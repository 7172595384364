// Navigation
$nav-height: 8.2rem;
$nav-height-small: 7rem;
$nav-height-phone: 5rem;
$nav-height-scrolled: 6.8rem;
$nav-height-scrolled-small: 5.8rem;
$nav-logo-container-width: 29.6rem;
$nav-logo-container-width-small: 25.3rem;

// Footer
$footer-height: 21.4rem;