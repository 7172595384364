@import './breakpoints';

.default-form {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;

    div.form-error {
        width: 80%;
        padding: 0.8rem;
        margin: 0;
        margin-bottom: 0.8rem;
    
        font-family: 'Noto Sans';
        font-weight: 400;
        font-size: 1.2rem;
        color: $form-error-color;
    
        background-color: $form-error-background;
    }

    div.form-row {
        display: flex;
        justify-content: stretch;
        flex-wrap: wrap;
        width: calc(100% + 0.8rem);
        margin-right: -0.8rem;

        @include media-max("tablet-up") { flex-direction: column; }

        .form-group {
            margin-right: 0.8rem;
            margin-bottom: 0.8rem;
        }
    }

    .stay-horizontal {
        flex: 3 3 0;
        display: flex;
        justify-content: stretch;
    }

    .form-group {
        flex: 3 3 0;
        display: flex;
        flex-direction: column;

        &.small { flex: 1 1 0; }
        &.medium { flex: 2 2 0; }

        label {
            font-family: 'Noto Sans';
            font-size: 1.2rem;
            font-weight: 400;
            text-align: left;
            color: $form-label-color;
        }

        & > label { white-space: nowrap; }
        
        div.field {
            display: flex;
            align-content: stretch;
            align-items: center;
            margin-top: 0.4rem;
            
            background-color: $form-input-background;

            transition: background-color .4s, border-color .4s;

            &.error {
                background-color: $form-error-background;
                border-color: $form-error-color;
                
                .PhoneInput { background-color: $form-error-background; }

                > .material-icons, input, .field-addon, .select-container .select__control .select__value-container .select__placeholder { color: $form-error-color; }
            }

            &.editor, &.textarea {
                align-items: flex-start;
            }

            &.checkbox {
                margin-top: 2.3rem;

                background: none;
                border: none;

                @include media-max("tablet-up") { margin-top: 0.4rem; }

                label {
                    display: inline-flex;
                    align-items: center;

                    cursor: pointer;

                    .checkbox-input {
                        display: flex;
                        align-items: center;
    
                        input { 
                            opacity: 0;
                            width: 0;
                            height: 0;

                            &:checked + .checkbox-control {
                                background: $form-checkbox-selected;

                                .material-icons {
                                    color: $text-white;
                                    opacity: 1;
                                }
                            }

                            &:hover:not(:checked) + .checkbox-control {
                                .material-icons { opacity: 1; }
                            }
                        }
    
                        .checkbox-control {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 1.4rem;
                            height: 1.4rem;
    
                            background: $form-input-background;
                            border: solid 0.2rem $form-border;

                            transition: background-color .4s;

                            .material-icons {
                                display: block;

                                color: $form-checkbox-hover;
                                opacity: 0;

                                transition: color .4s, opacity .2s;
                            }
                        }
                    }
    
                    .checkbox-label {
                        margin-right: 0.4rem;
                        font-size: 1.2rem;
                        text-align: right;

                        &.nowrap { white-space: nowrap; }
                    }
                }

                &.accept-data {
                    margin: 0;

                    label {
                        .checkbox-label {
                            margin-right: 0;
                            margin-left: 0.8rem;
                            text-align: left;
                        }
                    }
                }
            }

            &.rating {
                background: none;

                .rating {
                    padding: 0;
                    --value: 1;
                    --value-hover: 1;
                    --x: calc(100% * (var(--value) / 5));
                    --x-hover: calc(100% * (var(--value-hover) / 5));
                    block-size: 36px;
                    inline-size: calc(5 * 36px);
                    position: relative;
                    touch-action: manipulation;
                    -webkit-appearance: none;

                    &:hover:not(.selected) {
                        cursor: pointer;

                        &::-moz-range-track {
                            background: linear-gradient(to right, $reviews-stars-color-hover 0 var(--x-hover), $form-input-background 0 var(--x-hover));
                            block-size: 100%;
                            mask: repeat left center/36px url('./star_rate.svg');
                        }
    
                        &::-webkit-slider-runnable-track {
                            background: linear-gradient(to right, $reviews-stars-color-hover 0 var(--x-hover), $form-input-background 0 var(--x-hover));
                            block-size: 100%;
                            mask: repeat left center/36px url('./star_rate.svg');
                            -webkit-mask: repeat left center/36px url('./star_rate.svg');
                        }
                    }

                    &::-moz-range-track {
                        background: linear-gradient(to right, $reviews-stars-color 0 var(--x), $form-input-background 0 var(--x));
                        block-size: 100%;
                        mask: repeat left center/36px url('./star_rate.svg');
                    }

                    &::-webkit-slider-runnable-track {
                        background: linear-gradient(to right, $reviews-stars-color 0 var(--x), $form-input-background 0 var(--x));
                        block-size: 100%;
                        mask: repeat left center/36px url('./star_rate.svg');
                        -webkit-mask: repeat left center/36px url('./star_rate.svg');
                    }

                    &::-moz-range-thumb {
                        height: 36px;
                        opacity: 0;
                        width: 36px;
                    }

                    &::-webkit-slider-thumb {
                        height: 36px;
                        opacity: 0;
                        width: 36px;
                        -webkit-appearance: none;
                    }
                }
            }

            > .material-icons {
                display: block;
                color: $form-border;
            }
            
            > input {
                width: 100%;
                margin: 0;
                padding: 0.4rem;
            
                font-family: 'Roboto';
                font-size: 1.2rem;
                font-weight: 400;
                color: $text-black;
            
                background: none;
                border: none;

                &[type=number] {
                    -webkit-appearance: textfield;
                    -moz-appearance: textfield;
                    appearance: textfield;
                }

                &[type=number]::-webkit-inner-spin-button, &[type=number]::-webkit-outer-spin-button { 
                    -webkit-appearance: none;
                }
            }

            > textarea {
                flex-grow: 2;
                height: 7rem;
                margin: 0;
                margin-left: 0.4rem;
                padding: 0.4rem;

                font-family: 'Roboto';
                font-size: 1.2rem;
                line-height: 1.6rem;
                font-weight: 400;
                color: $text-black;

                background: none;
                border: none;
                resize: none;
            }

            .PhoneInput {
                flex-grow: 2;
                padding-left: 0.8rem;

                font-size: 1rem;

                background: $form-input-background;

                transition: background-color .4s;
    
                input {
                    flex-grow: 2;
                    width: 100%;
                    padding: 0.4rem;

                    font-family: 'Roboto';
                    font-size: 1.2rem;
                    border: none;
                    background: none;
                }
            }

            .stars-container {
                display: flex;
                align-items: center;
                height: 2.225rem;
                padding: 0.4rem;
            }
        }

        div.error {
            margin-top: 0.4rem;
        
            font-size: 1.2rem;
            font-family: 'Noto Sans';
            font-weight: 700;
            color: $form-error-color;
        }

        &.customer-message {
            .message {
                margin-top: 0.4rem;
                padding: 0 0.8rem;

                font-family: 'Roboto';
                font-size: 1.1rem;
                color: $text-black;
        
                p {
                    margin: 0 !important;
                    margin-bottom: 0.8rem !important;
                    
                    line-height: 1.5rem !important;
                    text-align: justify !important;
    
                    &:last-child { margin-bottom: 0 !important; }
                }
        
                ul, ol { margin: 0.8rem 0 !important; }
                h1, h2, h3, h4, h5, h6 { color: $text-black !important; }
                h1, h2, h3 { margin: 0.8rem 0 !important; }
                h4, h5, h6 { margin: 0.4rem 0 !important; }
            }
        }
    }
    
    .buttons-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        padding: 0;

        @include media-max("tablet-up") { 
            flex-direction: column; 
            align-items: flex-end;
        }

        div.pricing {
            margin-right: 0.8rem;

            font-family: 'Roboto';
            font-size: 1.2rem;
            line-height: 1.8rem;
            text-align: right;
            color: $form-label-color;

            @include media-max("tablet-up") { 
                margin-right: 0;
                margin-bottom: 0.8rem;
            }

            strong {
                font-size: 1.8rem;
                font-weight: 700;
            }

            i { font-weight: 300; }
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.8rem;
        
            font-family: 'Noto Sans';
            font-weight: 700;
            font-size: 1.2rem;
            line-height: 1.2rem;
            text-transform: uppercase;
            
            border: none;
            
            cursor: pointer;
            transition: all .4s;
            
            &[type=submit] {
                color: $text-white;
                background: $form-save-background;

                &:hover {
                    box-shadow: 0 0 0.8rem $form-save-shadow;  
                }

                &:disabled {
                    background: $form-save-disabled-background;
                    box-shadow: none;
                    cursor: not-allowed;
                }
            }
            
            .material-icons {
                display: block;
                color: $text-white;
            }

            span:not(.material-icons) { margin-left: 0.4rem; }
        }
    }
}