@import '../../styles/colors';
@import '../../styles/breakpoints';

button.ToTop {
    position: fixed;
    right: 1.6rem;
    bottom: 3.2rem;
    z-index: 150;
    margin: 0;
    padding: 0.8rem;

    color: $to-top-color;

    background: $to-top-background;
    border: none;
    border-radius: 50%;
    
    transform: scale(1) rotate(0);
    
    transition: transform .4s, color .4s, box-shadow .4s, padding .4s ease;
    
    cursor: pointer;

    @include media-max("phone") { 
        padding: 0.6rem;
    }
    
    &:hover {
        color: $to-top-color-hover;
        box-shadow: 0 0 0.8rem $to-top-shadow;
    }

    &.hidden {
        transform: scale(0) rotate(-90deg);
    }
}