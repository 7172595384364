@import '../../../styles/colors';
@import '../../../styles/breakpoints';

.Map {
    width: 100%;
    height: 45rem;

    border: none;

    transition: height .4s ease;

    @include media-between("desktop-up", "desktop-down") { height: 36rem; }
    @include media-between("tablet-down", "desktop-up") { height: 34.8rem; }
    @include media-between("tablet-up", "tablet-down") { height: 36rem; }
    @include media-between("phone", "tablet-up") { height: 34.8rem; }
    @include media-max("phone") { height: 34.8rem; }

    div.infos {
        h4 {
            margin: 0;

            font-family: 'Noto Sans';
            font-size: 1.2rem;
            line-height: 1.2rem;
            font-weight: 700;
            color: $page-title;
        }

        p {
            margin: 0.8rem 0;

            font-family: 'Roboto';
            font-size: 1rem;
            line-height: 1.3rem;
            font-weight: 400;
            color: $text-black;
        }

        a {
            font-family: 'Roboto';
            font-size: 1rem;
            line-height: 1.3rem;
            font-weight: 700;
            color: $page-link-color;
        }
    }
}