@import '../../../../styles/colors';
@import '../../../../styles/section';

div.ReviewBlock {
    margin-bottom: 1.6rem;
    
    div.review-content {
        width: 100%;

        h4 {
            margin: 0;
            margin-bottom: 0.2rem;

            font-family: 'Noto Sans';
            font-size: 1.2rem;
            line-height: 1.2rem;
            font-weight: 700; 
            text-align: left;
            color: $reviews-customer-color;
        }

        div.note {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 0.4rem;

            color: $reviews-stars-color;
        }

        div.review {
            @extend .default-section;

            max-width: 80%;
            margin: 0 auto;
            margin-left: 0.8rem;

            div.text p { text-indent: 0; }
        }
    }

    div.comment-content {
        width: 100%;
        margin-top: 0.4rem;

        h4 {
            margin: 0;
            margin-bottom: 0.2rem;

            font-family: 'Noto Sans';
            font-size: 1.2rem;
            line-height: 1.2rem;
            font-weight: 700; 
            text-align: right;
            color: $reviews-customer-color;
        }

        div.date { text-align: right; }

        div.comment {
            @extend .default-section;

            max-width: 80%;
            margin: 0 auto;
            margin-right: 0.8rem;

            div.text p { text-indent: 0; }
        }
    }

    div.review-content, div.comment-content {
        div.date {
            margin-bottom: 0.4rem;

            font-family: 'Noto Sans';
            font-size: 1.1rem;
            line-height: 1.1rem;
            font-weight: 400;
            font-style: italic;
            color: $reviews-date-color;

            &::first-letter { text-transform: uppercase; }
        }

        div.text {
            span.three-dots { padding-right: 0.4rem; }
            button.show-more-button {
                margin: 0;
                padding: 0;

                font-family: 'Roboto';
                font-size: 1.2rem;
                line-height: 1.2rem;
                font-weight: 700;
                text-decoration: underline;
                color: $page-link-color;

                border: none;
                background: none;

                cursor: pointer;
            }
        }
    }
}