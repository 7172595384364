@import '../../../styles/colors';

div.Reviews {
    padding: 3.2rem;

    background: $reviews-background;

    h2 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
        margin-bottom: 1.6rem;

        font-family: 'Noto Sans';
        font-size: 2rem;
        line-height: 2rem;
        font-weight: 700;

        color: $page-title;

        div.mean-note {
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            margin-left: 0.8rem;

            color: $reviews-stars-color;
        }
    }

    div.reviews-container {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;
        margin-bottom: -1.6rem;

        p.no-reviews {
            width: calc(100% - 3.2rem);
            margin: 1.6rem;
            font-family: 'Roboto';
            font-size: 1.1rem;
            line-height: 1.1rem;
            text-align: center;
        }
    }

    ul.paginate {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        margin-top: 1.6rem;
        padding: 0;

        list-style: none;

        li.previous, li.next {
            color: $paginate-text;

            cursor: pointer;

            transition: color .4s;

            &.disabled {
                color: $paginate-text-disabled;
                cursor: default;
            }

            &:hover:not(.disabled) { color: $paginate-text-active; }
        }

        li:not(.previous):not(.next):not(.paginate-break) {
            margin: 0 0.8rem;

            font-family: 'Roboto';
            font-size: 1.2rem;
            line-height: 1.2rem;
            font-weight: 400;
            color: $paginate-text;

            transition: color .4s;

            cursor: pointer;

            &.active { 
                font-weight: 500; 
                color: $paginate-text-active;
            }

            &:hover { color: $paginate-text-active; }
        }
    }
}