@import '../../../styles/colors';
@import '../../../styles/breakpoints';

div.FullscreenSlideshow {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 160;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 100%;

    background: $fullscreen-slideshow-background;
    backdrop-filter: blur(0.2rem);

    &.hidden {
        visibility: hidden;
        backdrop-filter: blur(0);

        .slideshow-block {
            opacity: 0;
            transform: translateY(100%);
        }
    }

    .slideshow-block {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        width: 100%;
        height: 100%;

        opacity: 1;
        transform: translateY(0);

        transition: opacity .4s ease, transform .4s ease;

        .close-button {
            position: absolute;
            display: block;
            top: 1.6rem;
            right: 1.6rem;
            opacity: .4;
            z-index: 10;

            border: none;
            background: none;

            cursor: pointer;

            transition: opacity .4s, top .4s ease, right .4s ease;

            @include media-max("tablet-up") {
                top: 0.8rem;
                right: 0.8rem;
            }

            &:hover { opacity: 1; }

            .material-icons {
                display: block;
                line-height: 1.8rem;
                color: $fullscreen-slideshow-close-text;
                text-shadow: 0 0 0.4rem $home-slideshow-arrow-shadow;
            }
        }

        .carousel-root { 
            height: 100%;
            width: 100%; 
            display: flex;
            flex-direction: column;
        
            .carousel {
                &.carousel-slider {
                    height: 100%;

                    .control-arrow {
                        padding: 0;
                        opacity: .4;

                        transition: padding .4s ease;
                        
                        &.control-prev::before, &.control-next::before { display: none; }
                        &.control-prev { padding-left: 0.8rem; }
                        &.control-next { padding-right: 0.8rem; }

                        &.control-prev, &.control-next {
                            @include media-max("tablet-up") { padding: 0; }
                        }

                        &:hover { opacity: 1; background: none; }
                        &:disabled {
                            opacity: 0;
                            cursor: default;
                        }
        
                        .material-icons {
                            color: $home-slideshow-arrow-color;
                            text-shadow: 0 0 0.4rem $home-slideshow-arrow-shadow;
                        }
                    }

                    .slider-wrapper {
                        height: 100%;

                        ul.slider {
                            height: 100%;

                            li.slide {
                                padding: 1.6rem 4.4rem;

                                transition: padding .4s ease;

                                @include media-max("tablet-down") { padding: 0; }

                                div.slide-image {
                                    height: 100%;
                                    overflow: hidden;
                
                                    img {
                                        display: block;
                                        height: 100%;
                                        width: 100%;
                
                                        object-fit: contain;
                                        object-position: center center;

                                        pointer-events: auto;
                                    }

                                    .legend {
                                        width: 100%;
                                        bottom: 0;
                                        margin: 0;
                                        margin-left: -50%;
                                        padding: 1.6rem 12.8rem;
                                        opacity: 0;

                                        font-family: 'Roboto';
                                        font-size: 1.1rem;
                                        line-height: 1.4rem;
                                        font-weight: 400;
                                        text-align: center;
                                        color: $text-white;

                                        background: $fullscreen-slideshow-background;
                                        border: none;
                                        border-radius: unset;

                                        transition: opacity .8s ease, padding .4s ease;

                                        @include media-max("tablet-down") { padding: 1.6rem; }

                                        h3 {
                                            margin: 0;
                                            margin-bottom: 0.8rem;

                                            font-family: 'Noto Sans';
                                            font-size: 1.2rem;
                                            line-height: 1.2rem;
                                            font-weight: 700;
                                            text-align: center;
                                        }
                                    }
                                }

                                &.selected div.slide-image {
                                    img + .legend { opacity: 1; }
                                    img:hover + .legend { opacity: 0; }
                                }
                            }
                        }
                    }
                }

                &:not(.carousel-slider) {
                    .thumbs-wrapper {
                        margin: 0;
                        padding: 0.8rem;
                        
                        background: $fullscreen-slideshow-background;

                        ul.thumbs {
                            width: max-content;
                            margin: 0 auto;
                            padding: 0;

                            li.thumb {
                                margin: 0;
                                margin-right: 0.8rem;
                                padding: 0;
                                width: auto !important;
                                height: 4.8rem;

                                border: none;
                                cursor: pointer;

                                img {
                                    display: block;
                                    height: 100%;

                                    object-fit: contain;
                                    object-position: center center;

                                    filter: brightness(100%);

                                    transition: filter .4s;
                                }

                                &:not(.selected) img { filter: brightness(40%); }
                                &:hover:not(.selected) img { filter: brightness(80%); }
                            }
                        }
                    }
                }
            }
        }
    }
}