@import './breakpoints';

@keyframes lazyload-image-animation {
    from {
        background-position: 0 0;
    } to {
        background-position: 100% 100%;
    }
}

.default-section {
    display: flex;
    align-items: stretch;
    margin: 0.8rem 0;

    div.text {
        font-family: 'Roboto';
        font-size: 1.2rem;
        line-height: 1.6rem;
        margin-bottom: -0.8rem;
        color: $text-black;

        h1, h2, h3, h4, h5, h6 { 
            margin: 0.4rem 0;
            padding: 0;

            font-family: 'Noto Sans'; 
            font-weight: 600;
            text-align: left;
            color: $page-title;
        }

        h1 {
            font-size: 2.2rem;
            line-height: 2.8rem;
        }

        h2 {
            font-size: 2rem;
            line-height: 2.6rem;
        }

        h3 {
            font-size: 1.8rem;
            line-height: 2.4rem;
        }

        h4 {
            font-size: 1.6rem;
            line-height: 2rem;
        }

        h5 {
            font-size: 1.4rem;
            line-height: 1.8rem;
        }

        h6 {
            font-size: 1.2rem;
            line-height: 1.6rem;
        }

        p {
            margin: 0;
            margin-bottom: 0.8rem;

            text-align: justify;
            text-indent: 3.2rem;

            b, strong { 
                font-weight: 700;
                color: $page-bold-color; 
            }
        }

        a { 
            font-weight: 700;
            color: $page-link-color;
        }

        ul, ol {
            margin: 0;
            margin-top: 0.4rem;
            margin-bottom: 0.8rem;
            padding-left: 3.2rem;
        }
    }

    div.images-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1.6rem 0;
        margin-right: -1.6rem;

        transition: margin .4s ease;
        
        div.image {
            width: fit-content;
            height: 19.2rem;
            margin-right: 1.6rem;
            overflow: hidden;
            
            box-shadow: none;
            border-radius: 0.8rem;
            
            transform: scale(1);
            transition: transform .4s ease, box-shadow .4s ease, width .4s ease, height .4s ease, margin .4s ease;
            cursor: pointer;

            @include media-between("tablet-down", "desktop-down") { height: 15.4rem; }

            @include media-max("tablet-down") {
                height: fit-content;
                margin: 0;
                margin-bottom: 1.6rem;
            }

            @include media-between("tablet-up", "tablet-down") { width: calc(100% - 12.8rem); }
            @include media-between("phone", "tablet-up") { width: calc(100% - 6.4rem); }
            @include media-max("phone") { width: 100%; }

            div.lazyload-wrapper { height: 100%; }

            div.lazyload-image {
                width: 19.2rem;
                height: 19.2rem;

                background: linear-gradient(145deg, $lazyload-grad-start, $lazyload-grad-end, $lazyload-grad-start, $lazyload-grad-end);
                background-size: 300% 300%;

                animation: lazyload-image-animation 2s infinite linear;
                transition: width .4s ease, height .4s ease;

                @include media-between("tablet-down", "desktop-down") { 
                    width: 15.4rem;
                    height: 15.4rem; 
                }
                @include media-max("tablet-down") { width: 100%; }
            }

            img {
                display: block;
                height: 19.2rem;
                object-position: center center;

                transform: scale(1);

                transition: transform .4s ease, height .4s ease;

                @include media-between("tablet-down", "desktop-down") { height: 15.4rem; }
                @include media-max("tablet-down") {
                    height: auto;
                    width: 100%;
                }
            }

            &:hover {
                box-shadow: 0 0 0.8rem $page-image-shadow;
                transform: scale(1.05);

                img { transform: scale(1.05); }
            }
        }

    }

    &.images-left {
        div.text { order: 2; }
        div.images-container { 
            order: 1;
            margin-right: 0; // Compensation margin img
        }
    }

    &.images-under { flex-direction: column; }

    &.images-right {
        div.text { order: 1; }
        div.images-container { 
            order: 2;
            margin-left: 1.6rem;
        }
    }

    &.images-left, &.images-right { align-items: center; }

    @include media-max("desktop-up") {
        &.images-left, &.images-under, &.images-right { 
            flex-direction: column; 

            div.text { order: 1; }

            div.images-container {
                order: 2;
                margin: 1.6rem 0;
                margin-right: -1.6rem;
            }
        }
    }

    @include media-max("tablet-down") {
        &.images-left, &.images-under, &.images-right { 
            div.images-container {
                flex-direction: column;
                width: 100%;
                margin-right: 0;
                margin-bottom: 0;
            }
        }
    }
}