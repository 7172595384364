@import '../../styles/colors';
@import '../../styles/dims';
@import '../../styles/section';
@import '../../styles/breakpoints';

div.Page {
    position: relative;
    width: 100%;
    opacity: 1;
    overflow-y: hidden;

    transition: opacity .8s ease;

    &.hidden { 
        opacity: 0; 
        transition: none;
    }

    &.notice {
        div.header-image-container { display: none; }

        article.content-container {
            margin-top: calc(3.2rem + #{$nav-height-scrolled});

            @include media-between("tablet-down", "desktop-down") { margin-top: calc(3.2rem + #{$nav-height-scrolled-small}); }
            @include media-between("phone", "tablet-down") { margin-top: $nav-height-scrolled-small; }
            @include media-max("phone") { margin-top: $nav-height-phone; }

            div.sections-container section.section div.text p { text-indent: 0; }
        }
    }

    &:not(.notice) article.content-container div.sections-container section.section div.text p:first-of-type::first-letter { 
        padding-right: 0.2rem;
        font-size: 3.6rem;
        font-weight: 700;
        color: $page-link-color;
    }
    
    div.header-image-container {
        width: 96rem;
        height: calc(38.4rem + #{$nav-height});
        z-index: 49;
        margin: 0 auto;
        overflow: hidden;

        background: $lazyload-grad-end;

        transition: width .4s ease, height .4s ease;

        @include media-between("desktop-up", "desktop-down") {
            width: 76.8rem;
            height: calc(38.4rem + #{$nav-height-small});
        }

        @include media-max("desktop-up") {
            width: 100%;
            height: 38.4rem;
        }

        @include media-between("phone", "tablet-up") { height: 32rem; }
        @include media-max("phone") { height: 28.8rem }

        img.header-image {
            position: relative;
            display: block;
            height: 100%;
            width: 100%;
    
            object-fit: cover;
            object-position: center calc(50% + #{$nav-height / 2});

            transition: object-position .4s ease;

            @include media-between("desktop-up", "desktop-down") { object-position: center calc(50% + #{$nav-height-small / 2}); }
            @include media-max("desktop-up") { object-position: center center; }
        }
    }

    article.content-container {
        position: relative;
        width: 80rem;
        margin: 0 auto;
        margin-top: -8rem;
        margin-bottom: calc(3.2rem + #{$footer-height});
        padding: 3.2rem;
        z-index: 50;
        overflow-x: hidden;
    
        background: $page-background;

        transition: width .4s ease, margin .4s ease;

        @include media-between("desktop-up", "desktop-down") {
            width: 64rem;
            margin-top: -6.4rem;
        }

        @include media-between("tablet-down","desktop-up") {
            width: 57rem;
            margin-top: -6.4rem;
        }

        @include media-max("tablet-down") {
            width: 100%;
            margin-top: 0;
            margin-bottom: $footer-height;
        }
    
        h2 {
            margin: 0;
            margin-bottom: 1.6rem;
            padding: 0;
    
            font-family: 'Billion Dreams';
            font-size: 3.8rem;
            line-height: 3.8rem;
            font-weight: 400;
            text-align: center;
            color: $page-title;
        }
    
        div.sections-container {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            margin-bottom: -1.6rem;
    
            section.section {
                @extend .default-section;
            }
        }
    }
}