@import '../../styles/colors';
@import '../../styles/dims';
@import '../../styles/breakpoints';

div.Navigation {
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    height: $nav-height;

    background-color: $nav-background;
    box-shadow: 0 0 0.2rem $nav-shadow;

    transition: height .8s, background-color .8s;

    @include media-between("tablet-up", "desktop-down") { height: $nav-height-small; }
    @include media-between("phone", "tablet-up") { 
        height: $nav-height-scrolled-small; 
        transition: height .4s ease;
    }
    
    @include media-max("phone") { 
        height: $nav-height-phone; 

        transition: height .4s ease;
    }

    nav {
        height: 100%;
        padding-top: 2.4rem;

        transition: padding .8s;

        @include media-max("desktop-down") { padding-top: calc(2.4rem - #{$nav-height - $nav-height-small}); }

        ul {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60%;
            height: 100%;
            margin: 0 auto;
            padding: 0;

            list-style: none;

            @include media-max("tablet-up") {
                justify-content: space-between;
                width: 100%;
            }

            li {
                display: flex;
                align-items: stretch;
                min-height: 100%;
                margin: 0 0.8rem;
                
                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 10.6rem;

                    cursor: pointer;
                    text-decoration: none;

                    span.chamois {
                        margin-right: 0.8rem;
    
                        opacity: 0;
                        transform: translateX(0.8rem);
    
                        transition: opacity .4s, transform .4s;
    
                        img {
                            display: block;
                            height: 2.8rem;
    
                            filter: $nav-foreground-filter;
                        }
                    }

                    span.name {
                        display: block;
                        
                        font-family: 'Noto Sans';
                        font-size: 1.6rem;
                        line-height: 2.8rem;
                        font-weight: 500;
                        text-transform: uppercase;
                        text-decoration: none;
                        color: $nav-text-color;
    
                        transform: translateX(-1rem);
    
                        transition: color .4s, transform .4s;
                    }
                }

                &.logo-small-container {
                    display: none;
                    position: relative;
                    margin-top: calc(-2.4rem + #{$nav-height - $nav-height-small});

                    transition: margin .8s;

                    @include media-max("desktop-up") { display: block; }

                    img {
                        display: block;
                        height: $nav-height-scrolled-small;
                        padding: 0.4rem;

                        transition: padding .8s, height .4s ease;

                        @include media-max("tablet-up") { padding: 0.6rem; }
                        @include media-max("phone") { height: $nav-height-phone; }
                    }
                }
                
                &.logo-container {
                    display: block;
                    position: relative;
                    min-width: $nav-logo-container-width;

                    transform: translateY(0);

                    transition: transform .8s, min-width .8s;

                    @include media-between("desktop-up", "desktop-down") { min-width: $nav-logo-container-width-small; }
                    @include media-max("desktop-up") { display: none; }

                    img {
                        display: block;
                        height: $nav-height;
                        margin: 0 auto;

                        transition: height .8s;

                        @include media-max("desktop-down") { height: $nav-height-small; }

                        &.logo-foreground {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            z-index: 100;
                            filter: $nav-foreground-filter; 
                        }

                        &.logo-background {
                            filter: $nav-background-filter;
                            transform: scale(0.98);
                        }
                    }
                }

                &.open-menu button {
                    z-index: 101;

                    margin-top: calc(-2.4rem + #{$nav-height - $nav-height-small});
                    padding: 0.4rem;

                    color: $nav-text-color-active;

                    border: none;
                    background: none;

                    cursor: pointer;

                    transition: margin .8s, color .4s;
                    
                    &:hover { color: $nav-text-color-hover; }
                    
                    .material-icons {
                        transform: rotate(0);

                        transition: transform .2s;
                    }
                }

                &.open-menu.open .material-icons { transform: rotate(180deg); }
                
                &:hover a {
                    span.chamois {
                        opacity: 1;
                        transform: translateX(0);
                    }

                    span.name {
                        color: $nav-text-color-hover;

                        transform: translateX(0);
                    }
                }

                &.active a {
                    span.chamois {
                        opacity: 1;
                        transform: translateX(0);
                    }

                    span.name {
                        color: $nav-text-color-active;

                        transform: translateX(0);
                    }
                }
            }
        }

        div.small-menu {
            position: fixed;
            top: 0;
            bottom: 0;
            right: 0;
            width: 17.5rem;
            height: 100%;
            padding-top: $nav-height-scrolled-small;
            overflow: hidden;
            z-index: 100;

            background: $nav-background-scrolled;

            box-shadow: 0 0 0.2rem $nav-shadow;

            transform: translateX(17.8rem);

            transition: transform .4s ease, padding .8s;

            &.open, &.open ul li { transform: translateX(0); }

            &.open ul li { 
                @for $i from 1 through 10 {
                    &:nth-of-type(#{$i}) { transition: transform .4s #{($i - 1) * .1}s ease;}
                }
            }

            ul {
                flex-direction: column;
                justify-content: flex-start;

                @include media-max("phone") { justify-content: center; }

                li {
                    display: block;
                    min-height: fit-content;
                    margin: 0.8rem 0;

                    transform: translateX(17.8rem);

                    transition: transform .4s .4s ease;

                    a {
                        display: block;
                        width: 17.5rem;
                        margin: 0 auto;

                        font-family: 'Noto Sans';
                        font-size: 1.6rem;
                        line-height: 2.8rem;
                        font-weight: 400;
                        text-transform: uppercase;
                        text-decoration: none;
                        text-align: center;
                        color: $nav-text-color;

                        transition: color .4s;
                    }

                    &:hover a { color: $nav-text-color-hover; }
                    &.active a { color: $nav-text-color-active; }
                }
            }
        }
    }

    &.scrolled {
        height: $nav-height-scrolled;

        background-color: $nav-background-scrolled;

        @include media-between("phone", "desktop-down") { height: $nav-height-scrolled-small; }
        @include media-max("phone") { height: $nav-height-phone; }

        nav {
            padding-top: calc(2.4rem - #{$nav-height - $nav-height-scrolled});

            @include media-between("phone", "desktop-down") { padding-top: calc(2.4rem - #{$nav-height - $nav-height-scrolled-small}); }
            @include media-max("phone") { padding-top: calc(2.4rem - #{$nav-height - $nav-height-scrolled-small}); }

            .logo-small-container { 
                margin-top: 0;

                img { padding: 0.6rem; }
            }

            .logo-container {
                transform: translateY(-0.4rem);

                @include media-max("desktop-down") { transform: translateY(0.5rem); }

                img { 
                    height: 5.6rem; 

                    @include media-max("desktop-down") { height: 4.8rem; }
                }
            }

            .open-menu button { margin-top: calc(-2.4rem + #{$nav-height - $nav-height-scrolled-small}); }
        }
    }
}