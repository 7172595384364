@import '../../../styles/colors';
@import '../../../styles/dims';
@import '../../../styles/breakpoints';

@keyframes know-more-arrow {
    0% {
        opacity: .4;
        transform: translateY(0);
    }
    50% {
        opacity: 1;
        transform: translateY(-0.8rem);
    }
    100% {
        opacity: .4;
        transform: translateY(0);
    }
}

div.Slideshow {
    position: relative;
    height: 100vh;

    .carousel-root, .carousel, .slider-wrapper, .slider, .slide { height: 100%; }

    .carousel {
        .control-arrow {
            padding: 0;
            opacity: .4;

            &:hover { opacity: 1; background: none; }
            &.control-prev::before, &.control-next::before { display: none; }

            .material-icons {
                color: $home-slideshow-arrow-color;
                text-shadow: 0 0 0.4rem $home-slideshow-arrow-shadow;
            }
        }

        li.slide {
            div.slide-image {
                position: relative;
                height: 100%;
                overflow: hidden;

                transition: top .4s ease, bottom .4s ease, left .4s ease, right .4s ease;

                &.top-left:after, &.top-right:after, &.bottom-right:after, &.bottom-left:after {
                    position: absolute;
                    content: "";
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                }

                &.top-left:after { background: linear-gradient(-45deg, rgba(0,0,0,0) 50%, $home-slideshow-text-shadow 100%); }
                &.top-right:after { background: linear-gradient(45deg, rgba(0,0,0,0) 50%, $home-slideshow-text-shadow 100%); }
                &.bottom-right:after { background: linear-gradient(135deg, rgba(0,0,0,0) 50%, $home-slideshow-text-shadow 100%); }
                &.bottom-left:after { background: linear-gradient(225deg, rgba(0,0,0,0) 50%, $home-slideshow-text-shadow 100%); }

                img {
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                    background: $home-slideshow-text-shadow-no-alpha;

                    transform: scale(1.1);

                    transition: transform 8s;
                }

                .legend {
                    top: unset;
                    bottom: unset;
                    left: unset;
                    right: unset;
                    width: auto;
                    max-width: calc(100% - 12.8rem);
                    margin: 0;
                    padding: 0;
                    opacity: 0;
                    z-index: 90;
    
                    background: none;
                    border-radius: 0;
    
                    color: $home-slideshow-text-color;
                    text-shadow: 0 0 1.6rem $home-slideshow-text-shadow;
    
                    transition: opacity .8s ease .8s, transform .8s ease .8s;

                    @include media-max("desktop-down") { max-width: calc(100% - 6.4rem); }
        
                    h3 {
                        margin: 0;
                        margin-bottom: 0.8rem;
        
                        font-family: 'Noto Sans';
                        font-size: 3.2rem;
                        line-height: 3.6rem;
                        font-weight: 700;
                        color: $home-slideshow-header-color;

                        @include media-max("tablet-up") {
                            font-size: 2.8rem;
                            line-height: 3.2rem;
                        }
                    }
        
                    h4 {
                        margin: 0;
                        margin-bottom: 0.8rem;
        
                        font-family: 'Noto Sans';
                        font-size: 3rem;
                        line-height: 3.4rem;
                        font-weight: 700;
                        color: $home-slideshow-header-color;

                        @include media-max("tablet-up") {
                            font-size: 2.6rem;
                            line-height: 3rem;
                        }
                    }
        
                    h5 {
                        margin: 0;
                        margin-bottom: 0.4rem;
        
                        font-family: 'Noto Sans';
                        font-size: 2.8rem;
                        line-height: 3rem;
                        font-weight: 700;
                        color: $home-slideshow-header-color;

                        @include media-max("tablet-up") {
                            font-size: 2.4rem;
                            line-height: 2.6rem;
                        }
                    }
        
                    h6 {
                        margin: 0;
                        margin-bottom: 0.4rem;
        
                        font-family: 'Noto Sans';
                        font-size: 2.6rem;
                        line-height: 2.8rem;
                        font-weight: 700;
                        color: $home-slideshow-header-color;

                        @include media-max("tablet-up") {
                            font-size: 2.2rem;
                            line-height: 2.4rem;
                        }
                    }
        
                    p {
                        margin: 0;
        
                        font-family: 'Roboto';
                        font-size: 2.6rem;
                        line-height: 3rem;
                        font-weight: 400;

                        @include media-max("tablet-up") {
                            font-size: 2.2rem;
                            line-height: 2.6rem;
                        }
                    }
        
                    b { font-weight: 500; }
                }
    
                &.top-left, &.top-right { 
                    .legend {
                        top: calc(6.4rem + #{$nav-height});

                        @include media-between("tablet-up", "desktop-down") { top: calc(3.2rem + #{$nav-height-small}); }
                        @include media-between("phone", "tablet-up") { top: calc(3.2rem + #{$nav-height-scrolled-small}); }
                        @include media-max("phone") { top: calc(3.2rem + #{$nav-height-phone}); }
                    } 
                }
    
                &.bottom-left, &.bottom-right { 
                    .legend {
                        bottom: 10.8rem; 

                        @include media-between("phone", "desktop-down") { bottom: 7.6rem; }
                        @include media-max("phone") { bottom: 5.4rem; }
                    }
                }
    
                &.top-left, &.bottom-left { 
                    .legend {
                        left: 6.4rem;
                        text-align: left;
                        transform: translateX(-3.2rem);
                    }
                }
    
                &.top-right, &.bottom-right { 
                    .legend {
                        right: 6.4rem; 
                        text-align: right;
                        transform: translateX(3.2rem);
                    }
                }

                &.top-left .legend, &.top-right .legend, &.bottom-right .legend, &.bottom-left .legend {
                    @include media-max("desktop-down") { 
                        left: 3.2rem;
                        right: 3.2rem; 
                    }
                }
            }

            &.selected {
                div.slide-image {
                    .legend {
                        opacity: 1;
                        transform: translateX(0);
                    }
    
                    &.top-left, &.bottom-left { 
                        .legend.hidden {
                            opacity: 0 !important;
                            transform: translateX(-3.2rem) !important;
                        }
                    }
        
                    &.top-right, &.bottom-right { 
                        .legend.hidden {
                            opacity: 0 !important;
                            transform: translateX(3.2rem) !important;
                        }
                    }
    
                    img {
                        transform: scale(1) !important;
    
                        &.hidden { transform: scale(1.1) !important; }
                    }
                }
            }
        }
    }

    .know-more {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 3.6rem;
        padding: 0;
        margin: 0;
        margin-bottom: 0.8rem;

        color: $home-slideshow-text-color;
        text-shadow: 0 0 0.8rem $home-slideshow-text-shadow;

        border: none;
        background: none;

        cursor: pointer;

        > * {
            animation: know-more-arrow 1.4s ease 0s infinite both;
        }
    }
}