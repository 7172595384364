@import './styles/colors';
@import './styles/scrollbar';
@import './fonts/fontawesome/css/fontawesome.css';
@import './fonts/fontawesome/css/brands.css';

* {
    box-sizing: border-box;
}

:root {
    --toastify-color-success: #{$toast-color-success};
    --toastify-color-warning: #{$toast-color-warning};
    --toastify-color-error: #{$toast-color-error};

    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);
  
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error);
}

html,
body,
div.App,
div#root {
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    width: 100%;
    min-height: 100vh;

    font-size: 10pt;
    font-smooth: auto !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;

    background-color: $global-background;

    @include scrollbar($scrollbar-background, none);
}

.lazyload-placeholder {
    background: $page-background;
}

.Toastify__toast-container--top-left {
    top: 1rem;
    left: 1rem;
}
.Toastify__toast-container--top-center {
    top: 1rem;
}
.Toastify__toast-container--top-right {
    top: 1rem;
    right: 1rem;
}
.Toastify__toast-container--bottom-left {
    bottom: 1rem;
    left: 1rem;
}
.Toastify__toast-container--bottom-center {
    bottom: 1rem;
}
.Toastify__toast-container--bottom-right {
    bottom: 1rem;
    right: 1rem;
}

.MuiIcon-fontSizeTiny, .MuiIcon-fontSizeSmall, .MuiIcon-fontSizeMedium, .MuiIcon-fontSizeLarge { 
    font-family: 'Material Icons' !important;
    font-weight: normal !important;
    font-style: normal  !important;
    line-height: 1  !important;
    letter-spacing: normal !important;
    text-transform: none !important;
    display: block !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    direction: ltr !important;
    font-feature-settings: 'liga' !important;
    font-smooth: auto !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
}
.MuiIcon-fontSizeTiny { font-size: 18px !important; }
.MuiIcon-fontSizeSmall { font-size: 24px !important; }
.MuiIcon-fontSizeMedium { font-size: 36px !important; }
.MuiIcon-fontSizeLarge { font-size: 48px !important; }