@import '../../styles/colors';
@import '../../styles/dims';
@import '../../styles/forms';
@import '../../styles/section';

div.Review {
    opacity: 1;

    transition: opacity .8s ease;

    &.hidden { opacity: 0; }

    div.content-container {
        position: relative;
        width: 80rem;
        margin: 0 auto;
        margin-top: calc(3.2rem + #{$nav-height-scrolled});
        margin-bottom: calc(3.2rem + #{$footer-height});
        padding: 3.2rem;
        z-index: 50;
        overflow-x: hidden;
    
        background: $page-background;

        transition: margin .4s ease, width .4s ease;

        @include media-between("tablet-down", "desktop-down") { margin-top: calc(3.2rem + #{$nav-height-scrolled-small}); }
        @include media-between("desktop-up", "desktop-down") { width: 64rem; }
        @include media-between("tablet-down","desktop-up") { width: 57rem; }
        @include media-between("phone", "tablet-down") {
            width: 100%;
            margin-top: $nav-height-scrolled-small;
            margin-bottom: $footer-height;
        }

        @include media-max("phone") { 
            width: 100%;
            margin-top: $nav-height-phone;
            margin-bottom: $footer-height;
        }
    
        h2 {
            margin: 0;
            margin-bottom: 1.6rem;
            padding: 0;
    
            font-family: 'Billion Dreams';
            font-size: 3.8rem;
            line-height: 3.8rem;
            font-weight: 400;
            text-align: center;
            color: $page-title;
        }
    
        div.sections-container {
            display: flex;
            flex-direction: column;
            align-items: stretch;

            div.section {
                @extend .default-section;

                div.text p { text-indent: 0; }
            }
    
            div.create-review {
                padding: 1.6rem;

                background: $booking-form-background;

                transition: margin .4s ease;

                @include media-max("phone") {
                    margin-left: -1.6rem;
                    margin-right: -1.6rem;
                }

                .create-form {
                    @extend .default-form;
                }
            }
        }
    }
}