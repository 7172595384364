// Global
$global-background: #ECDFD6;
$text-white: #FBF4F5;
$text-black: #0A0C0E;
$svg-white: invert(99%) sepia(16%) saturate(580%) hue-rotate(285deg) brightness(100%) contrast(97%);
$svg-black: invert(3%) sepia(4%) saturate(3452%) hue-rotate(169deg) brightness(93%) contrast(96%);

// Navigation
$nav-background: #F6EFEACC;
$nav-background-scrolled: #F6EFEA;
$nav-text-color: #B47F59;
$nav-text-color-hover: #8C5F3F;
$nav-text-color-active: #5D3F2A;
$nav-foreground-filter: invert(15%) sepia(70%) saturate(400%) hue-rotate(341deg) brightness(95%) contrast(89%); // #5D3F2A
$nav-background-filter: invert(92%) sepia(4%) saturate(973%) hue-rotate(319deg) brightness(108%) contrast(93%); // #F6EFEA
$nav-shadow: #5D3F2A;

// Footer
$footer-background: #5D3F2A;
$footer-shadow: #5D3F2A;
$footer-text-color: #F6EFEA;
$footer-logo-filter: invert(92%) sepia(3%) saturate(978%) hue-rotate(324deg) brightness(106%) contrast(93%); // #F6EFEA

// ToTop
$to-top-background: #8C5F3F;
$to-top-shadow: #00000066;
$to-top-color: #ECDFD6;
$to-top-color-hover: #F6EFEA;

// Lazyload
$lazyload-grad-start: #FFFFFF;
$lazyload-grad-end: #F6EFEA;

// Home
$home-block-background: #FFFFFF;
$home-block-shadow: #00000022;
// Home slideshow
$home-slideshow-arrow-color: #FFFFFF;
$home-slideshow-arrow-shadow: #455761;
$home-slideshow-text-shadow: #17100ADD;
$home-slideshow-text-shadow-no-alpha: #17100A;
$home-slideshow-header-color: #C79F83;
$home-slideshow-text-color: #F6EFEA;
// Reviews
$reviews-background: #FFFFFF;
$reviews-customer-color: #0A0C0E;
$reviews-date-color: #754F34;
$reviews-stars-color: #D6A126;
$reviews-stars-color-hover: #E8C87D;
$paginate-text: #3B4A53;
$paginate-text-active: #0A0C0E;
$paginate-text-disabled: #A1B3BC; 

// Page
$page-title: #5D3F2A;
$page-link-color: #B12028;
$page-background: #FFFFFF;
$page-image-shadow: #0000002D;
$page-bold-color: #B12028;
// FullscreenSlideshow
$fullscreen-slideshow-background: #14191CE6;
$fullscreen-slideshow-close-text: #FBF4F5;

// Booking
$booking-form-background: #E3CFC1;
// Form
$form-border: #8C5F3F;
$form-label-color: #5D3F2A;
$form-checkbox-selected: #8C5F3F;
$form-checkbox-hover: #B47F59;
$form-placeholder: #B9C6CD;
$form-error-color: #B12028;
$form-error-background: #EC9397;
$form-input-background: #F6EFEA;
$form-save-background: #5D3F2A;
$form-save-disabled-background: #B47F59;
$form-save-shadow: #00000044;
// DatePicker
$date-picker-background: #FFFFFF;
$date-picker-shadow: #00000022;
$date-picker-arrow: #273237;
$date-picker-arrow-hover: #14191C;
$date-picker-day-color: #B47F59;
$date-picker-date: #0A0C0E;
$date-picker-date-selected: #FBF4F5;
$date-picker-outside-date: #8C5F3F;
$date-picker-disabled-date: #BE8F6E;
$date-picker-selected: #8C5F3F;
$date-picker-selected-range: #B47F59;
$date-picker-hover: #5D3F2A;
// EditorField
$editor-tools-background: #DABFAC;
$editor-tools-color: #BE8F6E;
$editor-tools-active-color: #5D3F2A;
$editor-tools-hover-color: #8C5F3F;
$editor-placeholder-color: #BE8F6E;

// BookingShortcut
$shortcut-background: #FFFFFF;
$shortcut-shadow: #00000022;
$shortcut-button-background: #B12028;
$shortcut-button-color: #FBF4F5;
$shortcut-hover-shadow: #00000044;

// NotFound
$not-found-text-color: #5D3F2A;
$not-found-filter: invert(15%) sepia(70%) saturate(400%) hue-rotate(341deg) brightness(95%) contrast(89%); // #5D3F2A

// Toastify
$toast-color-success: #425F31;
$toast-color-warning: #B47F59;
$toast-color-error: #B12028;

// Scrollbar
$scrollbar-background: #E8ECEE;