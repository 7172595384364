@import '../../styles/colors';
@import '../../styles/breakpoints';

div.BookingShortcut {
    position: fixed;
    right: 0;
    top: 50%;
    bottom: 50%;
    z-index: 80;
    display: flex;
    align-items: stretch;
    width: 20rem;
    height: 23.2rem;

    background: $shortcut-background;
    box-shadow: 0 0 0.8rem $shortcut-shadow;

    transform: translateY(-50%) translateX(0);

    transition: transform .4s ease, box-shadow .4s;

    @include media-max("tablet-up") {
        left: 1.2rem;
        right: unset;
        top: unset;
        bottom: 0;
        flex-direction: column;
        width: 18.8rem;
        height: 27.6rem;

        transform: translateY(0) translateX(0);
    }

    &:hover { box-shadow: 0 0 1.6rem $shortcut-hover-shadow; }

    &.hidden {
        box-shadow: none;
        transform: translateY(-50%) translateX(20rem);

        @include media-max("tablet-up") { transform: translateY(27.6rem) translateX(0); }
    }

    &.closed:not(.hidden) {
        transform: translateY(-50%) translateX(17.6rem);

        @include media-max("tablet-up") { transform: translateY(23.2rem) translateX(0); }
    }

    &.disabled { transition: none; }

    button.open-close-button {
        width: 2.4rem;
        padding: 0;

        border: none;
        background: $shortcut-button-background;

        cursor: pointer;

        @include media-max("tablet-up") { width: 100%; }

        div.rotate-text {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 23.2rem;
            height: 2.4rem;

            text-align: center;
            color: $shortcut-button-color;

            transform: rotate(-90deg) translateY(-10.4rem);

            @include media-max("tablet-up") { 
                width: 100%;
                height: 4.4rem;

                transform: rotate(0) translateY(0); 
            }

            div.open-close-text {
                width: 6rem;

                font-family: 'Roboto';
                font-weight: 500;
                font-size: 1.2rem;
                line-height: 1.2rem;
            }
        }
    }

    div.picker-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @include media-max("tablet-up") { height: 100%; }

        .dates-range {
            width: 16.2rem;

            .DayPicker-wrapper {
                padding-bottom: 0;
            }
        }

        div.pricing {
            font-family: 'Roboto';
            font-size: 1.2rem;
            line-height: 1.8rem;
            text-align: right;
            color: $form-label-color;

            strong {
                font-size: 1.8rem;
                font-weight: 700;
            }
        }

        div.error-response {
            height: 100%;
            margin: 0.8rem 1.6rem;

            font-family: 'Roboto';
            font-size: 1rem;
            line-height: 1.2rem;
            font-weight: 500;
            text-align: justify;
            color: $form-error-color;
        }

        button.booking-button {
            align-self: flex-end;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 0.8rem;
            margin-bottom: 0.8rem;
            padding: 0.4rem;
        
            font-family: 'Noto Sans';
            font-weight: 700;
            font-size: 1.2rem;
            line-height: 1.2rem;
            text-transform: uppercase;
            color: $text-white;

            background: $form-save-background;
            
            border: none;
            
            cursor: pointer;
            transition: all .4s;
            
            &:hover {
                box-shadow: 0 0 0.8rem $shortcut-hover-shadow;  
            }

            &:disabled {
                background: $form-save-disabled-background;
                box-shadow: none;
                cursor: not-allowed;
            }
            
            .material-icons {
                display: block;
                color: $text-white;
            }

            span:not(.material-icons) { margin-left: 0.4rem; }
        }
    }
}