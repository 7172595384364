@import '../../styles/colors';
@import '../../styles/breakpoints';

.DayPickerInput {
    width: 100%;
    
    input {
        width: 100%;
        margin: 0;
        padding: 0.4rem;
    
        font-family: 'Roboto';
        font-size: 1.2rem;
        font-weight: 400;
        color: $text-black;

        background: none;
        border: none;
    }
}

.DayPickerInput-Overlay {
    left: 50%;
    width: 18rem;
    padding: 0.8rem;

    background: $date-picker-background;

    box-shadow: 0 0 0.8rem $date-picker-shadow;

    transform: translateX(-50%);
}

.DayPicker-Month {
    width: 100%;
    margin: 0 1em;
    margin-top: 1em;
    border-spacing: 0.2rem;
    border-collapse: separate;
    table-layout: fixed;
}

.DayPicker-NavButton {
    top: 0.66rem;
    height: 24px;
    width: 24px;
    margin: 0;

    &:hover { opacity: 1; }
}

.DayPicker-NavButton--prev {
    left: 1.5rem;
    right: auto;
    margin-right: 0;
    background-image: none;

    &:before {
        content: '\e5cb';
        font-family: 'Material Icons';
        font-size: 24px;
        color: $date-picker-arrow;
        font-feature-settings: 'liga' 1;

        transition: color .4s;
    }

    &:hover:before { color: $date-picker-arrow-hover; }
}

.DayPicker-NavButton--next {
    background-image: none;

    &:after {
        content: '\e5cc';
        font-family: 'Material Icons';
        font-size: 24px;
        color: $date-picker-arrow;
        font-feature-settings: 'liga' 1;

        transition: color .4s;
    }

    &:hover:after { color: $date-picker-arrow-hover; }
}

.DayPicker-Caption {
    > div {
        font-family: 'Noto Sans';
        font-size: 1.1rem;
        line-height: 1.1rem;
        font-weight: 700;
        color: $text-black;
        text-align: center;
    
        &::first-letter { text-transform: capitalize; }
    }
}

.DayPicker-Weekday {
    padding: 0.4rem;
    height: 1.6rem;

    font-family: 'Roboto';
    font-size: 1rem;
    line-height: 1rem;
    color: $date-picker-day-color;
}

.DayPicker-Day {
    padding: 0.4rem;
    height: 1.8rem;

    font-family: 'Roboto';
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 400;
    text-align: center;
    color: $date-picker-date;

    border-radius: unset;

    transition: color .4s, background-color .4s;

    &:not(.DayPicker-Day--disabled):hover {
        background: $date-picker-hover !important;
        color: $date-picker-date-selected !important;
    }
}

.DayPicker-Day--today { font-weight: 700; }
  
.DayPicker-Day--outside { color: $date-picker-outside-date !important; }
  
.DayPicker-Day--disabled {
    color: $date-picker-disabled-date !important;
    cursor: default;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled) {
    background: $date-picker-selected !important;
    color: $date-picker-date-selected !important;
}

.dates-range {
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
        background: $date-picker-selected-range !important;

        &:hover { background: $date-picker-hover !important; }
    }
}