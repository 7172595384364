@font-face {
  font-family: 'Noto Sans';
  src: url('./fonts/noto_sans/NotoSans-Regular.ttf');
  font-weight: 400;
  font-display: block;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('./fonts/noto_sans/NotoSans-Italic.ttf');
  font-weight: 400;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('./fonts/noto_sans/NotoSans-Medium.ttf');
  font-weight: 500;
  font-display: block;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('./fonts/noto_sans/NotoSans-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('./fonts/noto_sans/NotoSans-SemiBold.ttf');
  font-weight: 600;
  font-display: block;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('./fonts/noto_sans/NotoSans-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('./fonts/noto_sans/NotoSans-Bold.ttf');
  font-weight: 700;
  font-display: block;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('./fonts/noto_sans/NotoSans-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/roboto/Roboto-Regular.ttf');
  font-weight: 400;
  font-display: block;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/roboto/Roboto-Italic.ttf');
  font-weight: 400;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/roboto/Roboto-Light.ttf');
  font-weight: 300;
  font-display: block;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/roboto/Roboto-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/roboto/Roboto-Medium.ttf');
  font-weight: 500;
  font-display: block;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/roboto/Roboto-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/roboto/Roboto-Bold.ttf');
  font-weight: 700;
  font-display: block;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/roboto/Roboto-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Billion Dreams';
  src: url('./fonts/billion_dreams/BillionDreams.ttf');
  font-weight: 400;
  font-display: block;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./fonts/nunito_sans/NunitoSans-Regular.ttf');
  font-weight: 400;
  font-display: block;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./fonts/nunito_sans/NunitoSans-Italic.ttf');
  font-weight: 400;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./fonts/nunito_sans/NunitoSans-ExtraLight.ttf');
  font-weight: 200;
  font-display: block;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./fonts/nunito_sans/NunitoSans-ExtraLightItalic.ttf');
  font-weight: 200;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./fonts/nunito_sans/NunitoSans-Light.ttf');
  font-weight: 300;
  font-display: block;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./fonts/nunito_sans/NunitoSans-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./fonts/nunito_sans/NunitoSans-SemiBold.ttf');
  font-weight: 600;
  font-display: block;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./fonts/nunito_sans/NunitoSans-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./fonts/nunito_sans/NunitoSans-Bold.ttf');
  font-weight: 700;
  font-display: block;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./fonts/nunito_sans/NunitoSans-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Material Icons';
  src: url('./fonts/MaterialIcons-Regular.ttf');
  font-weight: 400;
  font-display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:focus, textarea:focus, select:focus{
  outline: none;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: none;
  box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}