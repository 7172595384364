@import '../../styles/colors';

div.NotFound {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    z-index: 200;

    background: $global-background;

    h1 {
        margin: 0;
        margin-bottom: 0.4rem;
        padding: 0;

        font-family: 'Noto Sans';
        font-size: 9.6rem;
        line-height: 9.6rem;
        font-weight: 400;
        color: $not-found-text-color;

        img {
            display: inline-block;
            height: 9.6rem;

            filter: $not-found-filter;
        }
    }

    h2 {
        margin: 0;
        margin-bottom: 1.6rem;
        padding: 0;

        font-family: 'Noto Sans';
        font-size: 2.8rem;
        line-height: 2.8rem;
        color: $text-black;
    }

    p {
        margin: 0;
        margin-bottom: 0.8rem;
        padding: 0;

        font-family: 'Roboto';
        font-size: 1.2rem;
        line-height: 1.2rem;
        color: $text-black;
    }

    a {
        margin: 0;
        padding: 0;

        font-family: 'Roboto';
        font-size: 1.2rem;
        line-height: 1.2rem;
        color: $not-found-text-color;
    }
}