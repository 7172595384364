@import '../../styles/colors';
@import '../../styles/dims';
@import '../../styles/section';
@import '../../styles/breakpoints';

@keyframes block-image-animation {
    from {
        background-position: 0 0;
    } to {
        background-position: 100% 100%;
    }
}

div.Home {
    width: 100%;
    overflow-x: hidden;
    margin-bottom: $footer-height;
    opacity: 1;

    transition: opacity .8s ease;

    &.hidden { opacity: 0; }

    div.content-container {
        width: 80rem;
        margin: 0 auto;
        margin-top: 3.2rem;
        margin-bottom: 3.2rem;

        transition: width .4s ease, margin .4s ease;

        @include media-between("desktop-up", "desktop-down") { width: 64rem; }

        @include media-between("tablet-down","desktop-up") { width: 57rem; }

        @include media-max("tablet-down") {
            width: 100%;
            margin-top: 0;
            margin-bottom: 0;
        }

        article.home-content {
            margin-bottom: 3.2rem;
            padding: 3.2rem;

            background: $home-block-background;

            section.section {
                @extend .default-section;

                margin: 0;

                div.text p { text-indent: 0; }

                div.images-container div.image {
                    border-radius: 0;

                    cursor: default;

                    &:hover { 
                        transform: scale(1);
                        box-shadow: none;

                        img { transform: scale(1); }
                    }
                }
            }
        }

        div.blocks-container {
            display: flex;
            align-items: stretch;
            margin-right: -3.2rem;
            margin-bottom: 3.2rem;

            @include media-between("tablet-up", "tablet-down") { 
                padding: 0 3.2rem;
            }

            @include media-max("tablet-up") {
                flex-direction: column;
                width: 100%;
                margin: 0;
            }

            &.no-home-content { margin-top: 3.2rem; }

            a.block {
                display: block;
                width: 50%;
                margin-right: 3.2rem;

                font-family: 'Roboto';
                font-size: 1.2rem;
                text-decoration: none;
                color: $text-black;

                box-shadow: none;
                background: $home-block-background;

                transform: scale(1);
                transition: transform .4s ease, box-shadow .4s ease, width .4s ease, margin .4s ease;

                @include media-max("tablet-up") {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 3.2rem;
                }

                &:hover { 
                    box-shadow: 0 0 1.6rem $home-block-shadow;
                    transform: scale(1.05); 

                    div.description span.click-to { text-decoration: underline; }

                    div.image-container img.block-image { transform: scale(1.05); }
                }

                div.image-container {
                    width: 100%;
                    height: 24rem;
                    overflow: hidden;

                    transition: height .4s ease;

                    @include media-between("desktop-up", "desktop-down") { height: 19.2rem; }
                    @include media-between("tablet-up", "desktop-up") { height: 18rem; }
                    @include media-between("phone", "tablet-up") { height: 32.4rem; }

                    div.lazyload-wrapper { height: 100%; }

                    img.block-image {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center center;

                        transform: scale(1);
                        transition: transform .4s ease;
                    }

                    div.block-image-placeholder {
                        width: 100%;
                        height: 100%;

                        background: linear-gradient(145deg, $lazyload-grad-start, $lazyload-grad-end, $lazyload-grad-start, $lazyload-grad-end);
                        background-size: 300% 300%;

                        animation: block-image-animation 2s infinite linear;
                    }
                }

                div.description {
                    padding: 3.2rem;
                    padding-bottom: 6.4rem;
                    margin-bottom: -0.8rem;

                    h2 {
                        margin: 0;
                        margin-bottom: 1.6rem;

                        font-family: 'Noto Sans';
                        font-size: 2rem;
                        line-height: 2rem;
                        font-weight: 700;
                        color: $page-title;
                    }

                    p {
                        margin: 0;
                        margin-bottom: 0.8rem;

                        font-family: 'Roboto';
                        font-size: 1.2rem;
                        line-height: 1;
                        color: $text-black;
                        text-align: justify;
                    }

                    span.click-to {
                        position: absolute;
                        right: 3.2rem;
                        bottom: 3.2rem;

                        font-family: 'Roboto';
                        font-size: 1.2rem;
                        line-height: 1.2rem;
                        font-weight: 500;
                        color: $page-link-color;
                    }
                }
            }
        }

        article.localisation-container {
            margin-bottom: 3.2rem;
            padding: 3.2rem;

            background: $home-block-background;

            section.localisation {
                @extend .default-section;

                margin: 0;
                margin-bottom: 1.6rem;
                flex-direction: column;

                h2 {
                    margin: 0;
                    margin-bottom: 1.6rem;

                    font-family: 'Noto Sans';
                    font-size: 2rem;
                    line-height: 2rem;
                    font-weight: 700;
                    color: $page-title;
                }

                div.text p { text-indent: 0; }
            }
        }
    }
}