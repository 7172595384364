@import '../../styles/colors';
@import '../../styles/dims';

div.Footer {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: $footer-height;
    padding: 1.6rem;

    background: $footer-background;
    box-shadow: 0 0 0.2rem $footer-shadow;

    img.logo-simple {
        height: 4.8rem;

        filter: $footer-logo-filter;
    }

    .social-links {
        margin: 0.4rem 0;

        a:nth-of-type(2) { margin-left: 0.8rem; }
    }

    p {
        margin: 0;
        margin-top: 0.2rem;

        font-family: 'Roboto';
        font-size: 1.1rem;
        text-align: center;
        color: $footer-text-color;

        a.legal-notice {
            font-family: 'Roboto';
            font-size: 1.1rem;
            color: $footer-text-color;
            text-decoration: underline;
        }
        
        a.nicolas-carbonnier {
            font-family: 'Nunito Sans';
            font-size: 1.6rem;
            font-weight: 300;
            color: $footer-text-color;
            text-decoration: none;
            
            span.bold {
                font-weight: 600;
            }
        }
    }
}